import { Flex, Text } from '@chakra-ui/react';
import { generalUtils } from '@shared/common';

import { useCheckout } from '~/hooks/useCheckout';

export const OrderSummary = () => {
  const { selectedPlan, price, finalPrice, currency } = useCheckout();

  return (
    <Flex gap='0.25rem' flexDir='column' marginBottom='2rem'>
      <Flex justifyContent='space-between' color='black90'>
        <Text>Harmonia {selectedPlan?.name}</Text>
        <Text>{generalUtils.formatPrice(price, currency)}</Text>
      </Flex>
      <Flex
        justifyContent='space-between'
        color='error'
        marginBottom='0.75rem'
        paddingBottom='0.75rem'
        borderBottom='1px solid rgba(0, 0, 0, 0.1)'
      >
        <Text>The Special Discount (-{(100 - (finalPrice / price) * 100).toFixed(0)}%)</Text>
        <Text>-{generalUtils.formatPrice(price - finalPrice, currency)}</Text>
      </Flex>
      <Flex justifyContent='space-between' color='black90'>
        <Text>Shipping</Text>
        <Text>{generalUtils.formatPrice(15, currency)}</Text>
      </Flex>
      <Flex justifyContent='space-between' color='error'>
        <Text>Discount (-100%)</Text>
        <Text>-{generalUtils.formatPrice(15, currency)}</Text>
      </Flex>
      <Flex
        justifyContent='space-between'
        paddingTop='0.75rem'
        borderTop='1px solid rgba(0, 0, 0, 0.1)'
        marginTop='0.75rem'
      >
        <Text fontFamily='heading' fontSize='24px'>
          Total:
        </Text>
        <Text fontFamily='heading' fontSize='24px'>
          {generalUtils.formatPrice(finalPrice, currency)}
        </Text>
      </Flex>
    </Flex>
  );
};
