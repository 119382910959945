import { Box, Button, Flex, Input, Text } from '@chakra-ui/react';
import { FormEvent, memo, useRef } from 'react';

import SafeWebSVG from '~/assets/icons/safe_web.svg';
import { useAppLead } from '~/hooks/useAppLead';
import { useAppRouter } from '~/hooks/useAppRouter';
import { useCookies } from '~/hooks/useCookies';
import { useLeadMutation } from '~/hooks/useLeadMutation';
import { AppRoute } from '~/types';

import { Arrow } from '../Arrow';

export const EmailForm = memo(() => {
  const cookies = useCookies();
  const { pushNextRoute } = useAppRouter(AppRoute.LOADER);
  const { mutate, isLoading } = useLeadMutation({
    onMutate: () => {
      pushNextRoute();
    },
  });
  const { quizAnswers, id } = useAppLead();
  const emailRef = useRef<HTMLInputElement | null>(null);

  const handleSubmit = (event: FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    const email = emailRef?.current?.value;

    mutate({
      id,
      email,
      quizAnswers,
      customFields: cookies,
    });
  };

  return (
    <>
      <Box as='form' width='100%' onSubmit={handleSubmit} maxW='32rem'>
        <Input required ref={emailRef} type='email' marginBottom='1rem' placeholder='Your email address' />
        <Flex gap='0.75rem' alignItems='center' marginBottom='1.5rem'>
          <Flex>
            <SafeWebSVG />
          </Flex>
          <Text fontSize='13px' color='black50'>
            We don&apos;t send spam or share email addresses. We respect your privacy.
          </Text>
        </Flex>
        <Flex flexDir='column' gap='0.5rem' alignItems='center'>
          <Button type='submit' isLoading={isLoading}>
            Continue <Arrow dir='right' fill='white' />
          </Button>
          <Box display='inline-block'>
            <Box
              width={0}
              height={0}
              borderLeft='5px solid transparent'
              borderRight='5px solid transparent'
              borderBottom='5px solid black'
              margin='auto'
            />
            <Box bg='black' padding='0.5rem 1rem' borderRadius='0.5rem'>
              <Text fontSize='12px' color='white' lineHeight='125%'>
                SALE UP TO 65% OFF
              </Text>
            </Box>
          </Box>
        </Flex>
      </Box>
    </>
  );
});

EmailForm.displayName = 'EmailForm';
