import { Box, Button, CloseButton, Flex, Input, Text } from '@chakra-ui/react';
import { AppRoute } from '@shared/common';
import { FormEvent, useRef } from 'react';

import { useAppLead } from '~/hooks/useAppLead';
import { useAppRouter } from '~/hooks/useAppRouter';
import { useLeadMutation } from '~/hooks/useLeadMutation';

export const ShippingForm = () => {
  const { pushNextRoute, back } = useAppRouter(AppRoute.CHECKOUT);
  const { id, email } = useAppLead();
  const { mutate, isLoading } = useLeadMutation();

  const firstNameRef = useRef<HTMLInputElement | null>(null);
  const lastNameRef = useRef<HTMLInputElement | null>(null);
  const apartmentRef = useRef<HTMLInputElement | null>(null);
  const cityRef = useRef<HTMLInputElement | null>(null);
  const zipCodeRef = useRef<HTMLInputElement | null>(null);
  const emailRef = useRef<HTMLInputElement | null>(null);
  const phoneNumberRef = useRef<HTMLInputElement | null>(null);

  const handleFormSubmit = (event: FormEvent<HTMLFormElement>) => {
    event.preventDefault();

    mutate({
      id,
      shippingDetails: {
        firstName: firstNameRef?.current?.value || '',
        lastName: lastNameRef?.current?.value || '',
        apartment: apartmentRef?.current?.value || '',
        city: cityRef?.current?.value || '',
        zipCode: zipCodeRef?.current?.value || '',
        email: emailRef?.current?.value || '',
        phoneNumber: phoneNumberRef?.current?.value || '',
      },
    });

    pushNextRoute();
  };

  return (
    <Box width='100%'>
      <Flex justifyContent='right' marginBottom='1.5rem'>
        <CloseButton onClick={back} color='black' border='none' />
      </Flex>
      <Flex alignItems='center' marginBottom='0.75rem'>
        {/* <CheckCircleSVG fill={colors.primary} stroke={colors.primary} /> */}
        <Flex width='1.5rem' height='1.5rem' border='2px solid' borderColor='primary' borderRadius='2rem' />
        <Box flex={1} height='1px' bg='black10' />
        <Flex width='1.5rem' height='1.5rem' border='2px solid' borderColor='black10' borderRadius='2rem' />
        <Box flex={1} height='1px' bg='black10' />
        <Flex width='1.5rem' height='1.5rem' border='2px solid' borderColor='black10' borderRadius='2rem' />
      </Flex>
      <Flex justifyContent='space-between' marginBottom='1.5rem'>
        <Text fontSize='12px' fontWeight={600} color='black60'>
          SHIPPING
        </Text>
        <Text fontSize='12px' fontWeight={600} color='black60'>
          PAYMENT
        </Text>
        <Text fontSize='12px' fontWeight={600} color='black60'>
          RECEIPT
        </Text>
      </Flex>
      <Text
        textAlign='center'
        fontSize={{ base: '24px', md: '32px' }}
        fontFamily='heading'
        marginBottom={{ base: '1.5rem', md: '1.5rem' }}
      >
        Enter your shipping details
      </Text>
      <Box as='form' onSubmit={handleFormSubmit} maxW='24rem' margin='auto'>
        <Flex flexDir='column' gap='0.75rem' marginBottom='1.5rem'>
          <Flex gap='0.75rem'>
            <Input ref={firstNameRef} placeholder='First name' required />
            <Input ref={lastNameRef} placeholder='Last name' required />
          </Flex>
          <Input ref={apartmentRef} placeholder='Apartment, suite, etc. (optional)' />
          <Input ref={apartmentRef} placeholder='United States (FREE shipping)' disabled />
          <Input ref={cityRef} placeholder='Town / city' required />
          <Input ref={zipCodeRef} placeholder='Zip code' required />
          <Input ref={emailRef} placeholder='Email address' defaultValue={email} required />
          <Input ref={phoneNumberRef} placeholder='Phone number' required />
        </Flex>
        <Button type='submit' isLoading={isLoading}>
          Continue
        </Button>
      </Box>
    </Box>
  );
};
