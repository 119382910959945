import { ProductBase, Question } from '@shared/common';
import { PaymentProvider } from '@shared/payments';
import { FC } from 'react';

export const AppRoute = {
  QUIZ: '/quiz',
  EMAIL: '/email',
  SUMMARY: '/summary',
  LOADER: '/loader',
  SHIPPING: '/shipping',
  CHECKOUT: '/checkout',
  SUCCESS: '/success',
};

export interface LeadResponse {
  id: string;
}

export interface LeadData {
  id?: string;
  email?: string;
  quizAnswers?: Record<string, string>;
  customFields?: Record<string, string | null>;
  shippingDetails?: Record<string, string>;
}

export interface PaymentData {
  leadId: string;
  productId: string;
  paymentProvider: PaymentProvider;
  currency: string;
}

export interface QuizAnswers {
  weight: string;
  targetWeight: string;
  skin_changes: string;
  brain_fog: string;
  weight_gain: string;
  hungry_after_meal: string;
  challenging_lose_weight: string;
  increase_urination: string;
  suffer_from_headaches: string;
  moon_face: string;
  age: string;
  gender: 'male' | 'female';
  [key: string]: string;
}

export type Lead =
  | {
      id: string;
      email: string;
      isMetric: boolean;
      quizAnswers: QuizAnswers;
      selectedPlan: undefined;
    }
  | {
      id: undefined;
      email: undefined;
      isMetric: boolean;
      quizAnswers: QuizAnswers;
      selectedPlan: undefined;
    }
  | {
      id: string;
      email: string;
      isMetric: boolean;
      quizAnswers: QuizAnswers;
      selectedPlan: AppProduct;
    };

type AppQuestion = Question;

export interface AppProduct extends Omit<ProductBase, 'plan'> {
  plan: {
    interval: number;
  };
}

export type QuestionComp = FC<{ question: AppQuestion; onSelect: (answer: { answer: string }) => void }>;
