import { Box, Text, Flex } from '@chakra-ui/react';

import { useAppLead } from '~/hooks/useAppLead';

export const SummaryStressParameters = () => {
  const { quizAnswers } = useAppLead();
  const isSkinChanges = quizAnswers.skin_changes === 'Yes';
  const isBrainFog = quizAnswers.brain_fog === 'Yes';
  const isWeightGain = quizAnswers.weight_gain === 'Yes';
  const isFeelingHungry = quizAnswers.hungry_after_meal === 'Yes';
  const isSufferingFromHeadaches = quizAnswers.suffer_from_headaches === 'Yes';

  return (
    <Flex flexDir='column' gap='1.5rem' width='100%'>
      <Box>
        <Text marginBottom='0.375rem'>Cortisol level</Text>
        <Flex position='relative' gap='0.125rem' marginBottom='0.5rem'>
          <Box flex={1} height='10px' backgroundColor='#80C86D' borderRadius='0.5rem 0 0 0.5rem' />
          <Box flex={1} height='10px' backgroundColor='#80C86D' />
          <Box flex={1} height='10px' backgroundColor='#FFD882' />
          <Box flex={1} height='10px' backgroundColor='#FFD882' />
          <Box flex={1} height='10px' backgroundColor='#FFD882' />
          <Box flex={1} height='10px' backgroundColor='#FF5C97' />
          <Box flex={1} height='10px' backgroundColor='#FF5C97' borderRadius='0 0.5rem 0.5rem 0' />
          <Box
            position='absolute'
            height='24px'
            width='24px'
            borderRadius='1rem'
            backgroundColor='#340000'
            top='50%'
            transform='translateY(-50%)'
            border='2px solid white'
            left='84%'
            boxShadow='0 4px 4px 4px rgba(0,0,0, 0.05)'
          ></Box>
        </Flex>
        <Flex justifyContent='space-between'>
          <Text fontSize='12px' color='black60'>
            Low
          </Text>
          <Text fontSize='12px' color='black60'>
            Medium
          </Text>
          <Text fontSize='12px' color='black60'>
            High
          </Text>
        </Flex>
      </Box>
      {isSkinChanges ? (
        <Box>
          <Text marginBottom='0.375rem'>Skin changes</Text>
          <Flex position='relative' gap='0.125rem' marginBottom='0.5rem'>
            <Box flex={1} height='10px' backgroundColor='#80C86D' borderRadius='0.5rem 0 0 0.5rem' />
            <Box flex={1} height='10px' backgroundColor='#80C86D' />
            <Box flex={1} height='10px' backgroundColor='#FFD882' />
            <Box flex={1} height='10px' backgroundColor='#FFD882' />
            <Box flex={1} height='10px' backgroundColor='#FFD882' />
            <Box flex={1} height='10px' backgroundColor='#FF5C97' />
            <Box flex={1} height='10px' backgroundColor='#FF5C97' borderRadius='0 0.5rem 0.5rem 0' />
            <Box
              position='absolute'
              height='24px'
              width='24px'
              borderRadius='1rem'
              backgroundColor='#340000'
              top='50%'
              transform='translateY(-50%)'
              border='2px solid white'
              left='75%'
              boxShadow='0 4px 4px 4px rgba(0,0,0, 0.05)'
            ></Box>
          </Flex>
          <Flex justifyContent='space-between'>
            <Text fontSize='12px' color='black60'>
              Low
            </Text>
            <Text fontSize='12px' color='black60'>
              Medium
            </Text>
            <Text fontSize='12px' color='black60'>
              High
            </Text>
          </Flex>
        </Box>
      ) : null}
      {isBrainFog ? (
        <Box>
          <Text marginBottom='0.375rem'>Brain fog</Text>
          <Flex position='relative' gap='0.125rem' marginBottom='0.5rem'>
            <Box flex={1} height='10px' backgroundColor='#80C86D' borderRadius='0.5rem 0 0 0.5rem' />
            <Box flex={1} height='10px' backgroundColor='#80C86D' />
            <Box flex={1} height='10px' backgroundColor='#FFD882' />
            <Box flex={1} height='10px' backgroundColor='#FFD882' />
            <Box flex={1} height='10px' backgroundColor='#FFD882' />
            <Box flex={1} height='10px' backgroundColor='#FF5C97' />
            <Box flex={1} height='10px' backgroundColor='#FF5C97' borderRadius='0 0.5rem 0.5rem 0' />
            <Box
              position='absolute'
              height='24px'
              width='24px'
              borderRadius='1rem'
              backgroundColor='#340000'
              top='50%'
              transform='translateY(-50%)'
              border='2px solid white'
              left='80%'
              boxShadow='0 4px 4px 4px rgba(0,0,0, 0.05)'
            ></Box>
          </Flex>
          <Flex justifyContent='space-between'>
            <Text fontSize='12px' color='black60'>
              Low
            </Text>
            <Text fontSize='12px' color='black60'>
              Medium
            </Text>
            <Text fontSize='12px' color='black60'>
              High
            </Text>
          </Flex>
        </Box>
      ) : null}
      {isWeightGain ? (
        <Box>
          <Text marginBottom='0.375rem'>Difficulty of losing weight</Text>
          <Flex position='relative' gap='0.125rem' marginBottom='0.5rem'>
            <Box flex={1} height='10px' backgroundColor='#80C86D' borderRadius='0.5rem 0 0 0.5rem' />
            <Box flex={1} height='10px' backgroundColor='#80C86D' />
            <Box flex={1} height='10px' backgroundColor='#FFD882' />
            <Box flex={1} height='10px' backgroundColor='#FFD882' />
            <Box flex={1} height='10px' backgroundColor='#FFD882' />
            <Box flex={1} height='10px' backgroundColor='#FF5C97' />
            <Box flex={1} height='10px' backgroundColor='#FF5C97' borderRadius='0 0.5rem 0.5rem 0' />
            <Box
              position='absolute'
              height='24px'
              width='24px'
              borderRadius='1rem'
              backgroundColor='#340000'
              top='50%'
              transform='translateY(-50%)'
              border='2px solid white'
              left='88%'
              boxShadow='0 4px 4px 4px rgba(0,0,0, 0.05)'
            ></Box>
          </Flex>
          <Flex justifyContent='space-between'>
            <Text fontSize='12px' color='black60'>
              Low
            </Text>
            <Text fontSize='12px' color='black60'>
              Medium
            </Text>
            <Text fontSize='12px' color='black60'>
              High
            </Text>
          </Flex>
        </Box>
      ) : null}
      {isFeelingHungry ? (
        <Box>
          <Text marginBottom='0.375rem'>Hungriness level</Text>
          <Flex position='relative' gap='0.125rem' marginBottom='0.5rem'>
            <Box flex={1} height='10px' backgroundColor='#80C86D' borderRadius='0.5rem 0 0 0.5rem' />
            <Box flex={1} height='10px' backgroundColor='#80C86D' />
            <Box flex={1} height='10px' backgroundColor='#FFD882' />
            <Box flex={1} height='10px' backgroundColor='#FFD882' />
            <Box flex={1} height='10px' backgroundColor='#FFD882' />
            <Box flex={1} height='10px' backgroundColor='#FF5C97' />
            <Box flex={1} height='10px' backgroundColor='#FF5C97' borderRadius='0 0.5rem 0.5rem 0' />
            <Box
              position='absolute'
              height='24px'
              width='24px'
              borderRadius='1rem'
              backgroundColor='#340000'
              top='50%'
              transform='translateY(-50%)'
              border='2px solid white'
              left='70%'
              boxShadow='0 4px 4px 4px rgba(0,0,0, 0.05)'
            ></Box>
          </Flex>
          <Flex justifyContent='space-between'>
            <Text fontSize='12px' color='black60'>
              Low
            </Text>
            <Text fontSize='12px' color='black60'>
              Medium
            </Text>
            <Text fontSize='12px' color='black60'>
              High
            </Text>
          </Flex>
        </Box>
      ) : null}
      {isSufferingFromHeadaches ? (
        <Box>
          <Text marginBottom='0.375rem'>Headaches level</Text>
          <Flex position='relative' gap='0.125rem' marginBottom='0.5rem'>
            <Box flex={1} height='10px' backgroundColor='#80C86D' borderRadius='0.5rem 0 0 0.5rem' />
            <Box flex={1} height='10px' backgroundColor='#80C86D' />
            <Box flex={1} height='10px' backgroundColor='#FFD882' />
            <Box flex={1} height='10px' backgroundColor='#FFD882' />
            <Box flex={1} height='10px' backgroundColor='#FFD882' />
            <Box flex={1} height='10px' backgroundColor='#FF5C97' />
            <Box flex={1} height='10px' backgroundColor='#FF5C97' borderRadius='0 0.5rem 0.5rem 0' />
            <Box
              position='absolute'
              height='24px'
              width='24px'
              borderRadius='1rem'
              backgroundColor='#340000'
              top='50%'
              transform='translateY(-50%)'
              border='2px solid white'
              left='65%'
              boxShadow='0 4px 4px 4px rgba(0,0,0, 0.05)'
            ></Box>
          </Flex>
          <Flex justifyContent='space-between'>
            <Text fontSize='12px' color='black60'>
              Low
            </Text>
            <Text fontSize='12px' color='black60'>
              Medium
            </Text>
            <Text fontSize='12px' color='black60'>
              High
            </Text>
          </Flex>
        </Box>
      ) : null}
      {}
    </Flex>
  );
};
