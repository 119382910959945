import { Flex, Text, useBreakpointValue } from '@chakra-ui/react';
import { motion } from 'framer-motion';

export const FreeShippingBanner = () => {
  const xPosition = useBreakpointValue({ base: '-100%', md: '-55%' });
  const animationDuration = useBreakpointValue({ base: 20, md: 30 });

  return (
    <Flex
      width='100%'
      padding='0.875rem 4%'
      alignItems='center'
      bg='black'
      color='white'
      whiteSpace='nowrap'
      overflow='hidden'
      justifyContent='right'
    >
      <motion.div
        initial={{ x: '108%' }}
        animate={{ x: xPosition }}
        transition={{ duration: animationDuration, repeat: Infinity, ease: 'linear', type: 'just' }}
        style={{ display: 'flex', flex: 1, gap: '4rem', textAlign: 'right' }}
      >
        <Text fontSize='12px'>
          <b>FREE WORLDWIDE</b> shipping on all orders
        </Text>
        <Text fontSize='12px'>30-day money back guarantee</Text>
      </motion.div>
    </Flex>
  );
};
