import { Button, Flex } from '@chakra-ui/react';
import Link from 'next/link';

import FemaleSVG from '~/assets/icons/female.svg';
import MaleSVG from '~/assets/icons/male.svg';
import { useAppLead } from '~/hooks/useAppLead';
import { useTracking } from '~/hooks/useTracking';
import { AppRoute } from '~/types';

export const GenderSelection = () => {
  const { updateQuizAnswers } = useAppLead();
  const { quizQuestionAnswer } = useTracking();

  const handleSelection = (gender: 'male' | 'female') => {
    updateQuizAnswers({ gender });

    quizQuestionAnswer({
      category: '01_gender',
      label: gender,
    });
  };

  return (
    <Flex width='100%' gap='0.5rem' maxW='28rem'>
      <Link href={AppRoute.QUIZ}>
        <Button variant='black' onClick={() => handleSelection('male')}>
          <MaleSVG />
          Male
        </Button>
      </Link>
      <Link href={AppRoute.QUIZ}>
        <Button onClick={() => handleSelection('female')}>
          <FemaleSVG />
          Female
        </Button>
      </Link>
    </Flex>
  );
};
